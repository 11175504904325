.partnerCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  padding: 18px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.partnerCardText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

.partnerCardIcon {
  width: 66px;
  height: 66px;
  border-radius: 50%;
}
.cardImg {
  width: 66px;
  height: 66px;
  border-radius: 50%;
}

.partnerName {
  font-weight: 600;
  font-size: 24px;
}
.partnerCardSubtitle {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.partnerCard:hover {
  background-color: #e5e5e5;
  cursor: pointer;
}

.imagePlaceholder {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: #d9d9d9;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
}

.wrapper {
  width: 95%;
  margin: 0 auto;
  padding-top: 10px;
}

.form {
  margin-top: 20px;
}

.inputText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 3px;
  width: 100%;
  padding: 10px;
}

.btn {
  color: #fff;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.inputFile {
  display: none;
}
.inputFileLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
}

.FileView {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.inputFileLabel {
  width: 48%;
}

.labelfile {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
  background-color: #fff;
}

.labelfile {
  border-radius: 12px;
  padding: 10px;
  border: 1px solid #e5e5e5;
}

.imgUpload {
  width: 18px;
}

.selectView {
  width: 100%;
}
.selectInput {
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 10px;
  border-radius: 12px;
}
.selectInput:focus {
  outline: none;
}
.products {
  margin-top: 20px;
}

.productCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;
  align-items: center;
  margin-bottom: 20px;
  padding: 18px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
.cardImage {
  width: 88px;
  height: 88px;
  border-radius: 10%;
}
.cardImg {
  width: 88px;
  height: 88px;
  border-radius: 10%;
  object-fit: cover;
}

.productCard:hover {
  background-color: #e5e5e5;
  cursor: pointer;
}

.btnGroup {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.btnText {
  font-size: 16px;
  color: #666666;
}
