.inputView {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 30%;
}

.label {
  font-size: 1.2rem;
  font-weight: 500;
}

.inputView input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.inputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.btn {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.disabled {
  background-color: #ccc;
  cursor: not-allowed;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: white;
}
