.wrapper {
  width: 95%;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input {
  width: 100%;
  margin: 10px 0;
  display: flex;
  gap: 10px;
}

.input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 4px;
  border: 1px solid #ccc;
}
.user-data-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  transition: all 0.1s;
}

.user-data-item:hover {
  background-color: #f9f9f9;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  padding-left: 10px;
}

.nothing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding-top: 80px;
}

.comapign {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  transition: all 0.1s;
}

.link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.user-data {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.st0 {
  fill: #ffd700;
}

.value__icon {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: row;
}
