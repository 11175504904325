@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Lato", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #3f4fe0;
  border-radius: 4px;
}

.gradient-button {
  background: linear-gradient(182.28deg, #3244e4 1.92%, #1b278f 169.25%);
}

.list-icon-circle-selected {
  background-color: rgba(50, 68, 228, 0.05);
}

.list-icon-circle-selected>svg .stroke-path {
  stroke: #3f4fe0;
}

.list-icon-circle-selected>svg .fill-path {
  fill: #3f4fe0;
}

.list-icon-selected>svg .stroke-path {
  stroke: #ffffff;
}

.list-icon-selected>svg .fill-path {
  fill: #ffffff;
}

.shadow-container {
  box-shadow: 0px 2px 12px 0px #1d1d1d14;
}

.black-overlay {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%);
}

.list-icon-60 * {
  opacity: 0.6;
}

.quick-filter-wrapper {
  position: initial;
}

.quick-filter-container {
  bottom: 0;
}

.kilt-snackbar {
  opacity: 0;
  transform: scale(0.75, 0.5625);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.kilt-snackbar-active {
  opacity: 1;
  transform: none;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.kilt-snackbar-success {
  background-color: #2e7d32;
}

.kilt-snackbar-error {
  background-color: #d32f2f;
}

.bg-deal-status-pending {
  background-color: #ffdd1e;
}

.bg-deal-status-completed {
  background-color: #b6ef12;
}

.search-res-count-button .disc-rli-bounding-box .disc-throbber {
  width: 20px;
  height: 20px;
}

.promote-text {
  color: #3244e4;
}

.bg-campaign {
  background-color: rgba(63, 79, 224, 0.05);
}

.promotion-standart-selected {
  background-color: #1b278f;
}

.promotion-top-selected {
  background-color: #f3a939;
}

.promotion-button-selected {
  background-color: #f90870;
}

.listing-promotion-wrapper-top {
  background-color: #ffffff;
  margin: 8;
  border-style: solid;
  border-radius: 12px;
  box-shadow: 0px 0px 14.4px 0px #c9c9c9d4;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.listing-promotion-wrapper-top .search-result-image-wrapper {
  padding-left: 20px;
}

.listing-promotion-wrapper-top .bg-center.bg-cover {
  border-radius: 8px;
}

.listing-promotion-call-top,
.listing-promotion-call-button {
  background: linear-gradient(96.74deg, #f90870 1.6%, #3244e4 102.97%);
}

.whatsapp-button-wrapper-top,
.whatsapp-button-wrapper-button {
  background: linear-gradient(96.74deg, #f90870 1.6%, #3244e4 102.97%);
  border-radius: 12px;
  padding: 0.07rem;
  margin-bottom: 10px;
}

.whatsapp-button-wrapper-top a,
.whatsapp-button-wrapper-button a {
  background-color: white;
  border: 0;
}

.whatsapp-button-wrapper-top a span,
.whatsapp-button-wrapper-button a span {
  background: -webkit-linear-gradient(92.21deg,
      #f90870 -63.76%,
      #3244e4 127.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input-parent-error input {
  border: 1px solid #d32f2f;
}

@media (min-width: 1024px) {
  .quick-filter-wrapper {
    position: relative;
  }

  .quick-filter-container {
    bottom: unset;
    top: 48px;
  }

  .listing-promotion-wrapper-top {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0.05rem;
  }
}

@media (max-width: 520px) {
  .mbspace {
    margin-bottom: 250px;
  }
}

.publishDate {
  font-size: 14px;
  color: black;
  margin-top: 12px;
}

.map {
  width: 100vw;
  height: 100vh;
}