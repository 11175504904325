.linkView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  cursor: pointer;
}

.linkLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: 700;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left {
  width: 30%;
}
.profile {
  background: #f2f2f2;
  padding: 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.pfp img {
  width: 85px;
  height: 85px;
  border-radius: 12px;
}
.name {
  font-size: 18px;
  color: #010101;
}
.position {
  color: #8794a5;
  font-size: 16px;
}

.balanceCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  background: #f2f2f2;
  border-radius: 12px;
  padding: 8px 15px;
}

.balanceCardLeftTitle {
  font-size: 14px;
}
.balanceCardPrice {
  font-size: 16px;
}
.balanceCardRightTitle {
  font-size: 14px;
  color: #3f4fe0;
  cursor: pointer;
}
.settingsTitle {
  margin: 10px 0;
  font-size: 24px;
  color: #010101;
  font-weight: 700;
}

.right {
  width: 69%;
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px #1d1d1d14;
  border-radius: 12px;
}

.rightTitle {
  font-size: 24px;
  line-height: 24px;
  color: #010101;
  font-weight: 700;
  margin-bottom: 10px;
}

.sort {
  padding: 8px 10px;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: fit-content;
  border-radius: 12px;
  cursor: pointer;
}
.listings {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.listingCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
  padding: 20px 20px;
  align-items: center;
  border-radius: 12px;
}
.listingCardLeft {
}
.listingCardLeft img {
  width: 350px;
  height: 230px;
  border-radius: 12px;
  object-fit: cover;
}

.listingCardRight {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.listingPrice {
  font-size: 28px;
  color: #110d28;
  font-weight: 700;
}
.listingData {
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 16px;
  color: #566982;
  margin-bottom: 6px;
}
.listingAddress {
  font-size: 16px;
  color: #566982;
  margin-bottom: 20px;
}

.listingDescription {
  font-size: 16px;
  color: #566982;
  overflow: hidden;
  white-space: nowrap;
}

.listingCard:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.listingDataItem {
  display: flex;
  gap: 2px;
  align-items: center;
}

.sortMenu {
  position: absolute;
  background: #fff;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 2px 12px 0px #1d1d1d14;
  margin-top: 10px;
}

.sortMenuItem {
  padding: 10px;
  cursor: pointer;
  border-radius: 12px;
  margin: 2px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sortMenuItem:hover {
  background: #f5f5f5;
}
