.partnerCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  padding: 18px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.partnerCardText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

.partnerCardIcon {
  width: 66px;
  height: 66px;
  border-radius: 50%;
}
.cardImg {
  width: 66px;
  height: 66px;
  border-radius: 50%;
}

.partnerName {
  font-weight: 600;
  font-size: 24px;
}
.partnerCardSubtitle {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.partnerCard:hover {
  background-color: #e5e5e5;
  cursor: pointer;
}
.inputText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 3px;
  width: 100%;
  padding: 10px;
}
.wrapper {
  width: 95%;
  margin: 0 auto;
  padding-top: 10px;
}

.img {
  width: 300px;
  height: 300px;
}
.download {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
.btnYes {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-right: 10px;
  width: 100%;
}
.btnNo {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
}

.btnYes:hover {
  background-color: #218838;
}
.btnNo:hover {
  background-color: #c82333;
}
.textarea {
  width: 100%;
  height: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 10px;
}
.modalWrapper {
  width: 95%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.photoModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.photoModal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dataModal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.value {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}

.titleModal {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}

.data {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.left {
  width: 200px;
  height: 200px;
}
.right {
  width: 200px;
  height: 200px;
}
.left img {
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.right img {
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.dataLeft {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.noData {
  font-size: 16px;
  font-weight: 600;
  color: #666666;
  text-align: center;
  margin-top: 40px;
}
