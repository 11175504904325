.mainImage {
  width: 500px;
  height: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.main {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
}

.address {
  font-size: 1rem;
  margin: 10px 0;
  color: #666;
}

.descriptionBlock {
  margin: 10px 0;
}

.descriptionTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
}

.description {
  font-size: 1rem;
  margin: 10px 0;
  color: #666;
}

.infoTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
}

.infoBlock {
  margin: 10px 0;
  width: 100%;
}

.line {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.left,
.right {
  font-size: 1rem;
  color: #666;
}

.right {
  font-weight: bold;
}
